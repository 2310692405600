<template>
    <div>
        <h3>Targeted Companies</h3>
        <div class="col-sm-12">
            <div class="form-group">
                <label>Company</label>
                <Typeahead cdi_type="company" clear-on-select="true" @objectSelected="addTarget"  :input-attributes="{class: 'form-control'}" v-bind:ID.sync="companyID" />
            </div>
        </div>
        <table class="table table-responsive ">
            <thead>
            <tr>
                <th>Companies</th>
                <th class="number">Goal</th>
                <th></th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="target in state.object.data.company_targets" :key="target.sales_target_id">
                <td >
                    {{ target.company_name }}
                </td>
                <td><input class="form-control number" type="text" v-model="target.yoy_goal" /></td>
                <td><button class="btn btn-block" @click="saveTarget(target)">Save</button></td>
                <td><button class="btn btn-block btn-danger" @click="removeTarget(target.sales_target_id)"><span class="glyphicon glyphicon-trash"></span></button></td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

import { store } from '@/store/BusinessObject.store.js';
import Typeahead from "@/components/utils/typeaheads/Typeahead.vue";

export default {
    name: 'Targets',
    components: {Typeahead},
    data() {
        return {
            state: store.state,
            companyID: 0
        }
    },

    methods: {
        addTarget: function() {
            let scope = this;
            store.apiWithObjectLoad('new_target', { params: {
                    company_id: scope.companyID
                }
            }).then(function() {
                scope.companyID = 0;
            })
        },
        removeTarget: function(sales_target_id) {
            let scope = this;
            store.apiWithObjectLoad('delete_target', { params: {
                    sales_target_id: sales_target_id
                }
            }).then(function() {
                scope.companyID = 0;
            })
        },
        saveTarget: function(target) {
            let scope = this;
            store.apiWithObjectLoad('save_target', { params: {
                    sales_target_id: target.sales_target_id,
                    yoy_goal: target.yoy_goal
                }
            }, 'object.data').then(function() {
                scope.companyID = 0;
            })
        }
    }
}

</script>